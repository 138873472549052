import React, { useEffect, useState } from 'react';
import imgConsulenzaInformatica from './immagini/consulenzaInformatica.webp';
import imgSiti from './immagini/siti.png';
import imgFormazione from './immagini/formazione.jpg';
import ContenutoImmagineVideo from './ContenutoImmagineVideo';

function Chisiamo(props) {
    const altezzaContenitoreHome = 320;

    return (
        <>
        <div style={{
            minHeight:altezzaContenitoreHome,
            backgroundColor: "#F5F5F5",
            marginTop: 30,
            marginBottom: 40,
            marginLeft: 20,
            marginRight: 20,
            alignItems: "center",
            
            fontSize:16,
            lineHeight:1.4,
            padding:20
        }}
        >
<span style={{fontWeight:'bold', fontSize:20}}>Azienda Informatica Genova</span> è composta da un team con consolidata esperienza maturata nel campo dei sistemi informativi aziendali, nella progettazione, realizzazione e vendita di software e nei sistemi di telecomunicazione. 
<br/><br/>
Nella nostra squadra vi sono software engineer esperti nello sviluppo di App Mobile (iOS e Android) e nella produzione di siti internet professionali.
<br/><br/>
La realizzazione di progetti per svariati clienti business di prestigio e notorietà nazionale ha portato i componenti del team a crescere insieme professionalmente negli anni raggiungendo un elevato know-how anche per quanto concerne la personalizzazione del software e la Cyber Security.
<br/><br/>
Ricerca, innovazione e knowledge management sono i valori aziendali che perseguiamo ogni giorno.
<br/><br/>
Queste caratteristiche si riflettono direttamente sullo standard qualitativo di tutti i nostri prodotti e servizi con un fil rouge che è quello della ricerca della soddisfazione del cliente.
<br/><br/>
<span style={{fontWeight:'bold', fontSize:20}}>Sostenibilità:</span> crediamo che progresso e futuro debbano avere una sensibilità green che comprenda tra gli obiettivi primari la sostenibilità. 
<br/>
AIG si impegna nella promozione di pratiche sostenibili e responsabili con l’attuazione di direttive aziendali attente all’impatto ambientale mettendo in atto comportamenti ecologicamente virtuosi.
        </div>        
    </>
    )
}

export default Chisiamo;