import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import './index.css';
import Home from './Home';

import Parametri from './parametri';

import reportWebVitals from './reportWebVitals';

const packageJson = require('../package.json');

const ContenitoreApp = () => {
  const [sharedState, setSharedState] = useState(/* valore iniziale */);
  const [dimensioniApp, setDimensioniApp] = useState({x: 0, y:0, flagPaginaOrizzontale:false});
  const larghezzaMinimaPerPaginaOrizzontale = 800;

  const versioneCorrenteNonAggiornata = (ultimaVersione, versioneCorrente) => {
    const versionsA = ultimaVersione.split(/\./g);
  
    const versionsB = versioneCorrente.split(/\./g);
    while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());
  
    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
    }
    return false;
  };

  function ControlloVersione() {        
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({op:"versione"})
    };
  
    fetch(Parametri("serverURL"), requestOptions)
        .then(risposta => risposta.json())
        .then(
        (valoreDiRitorno) => {          
            if(valoreDiRitorno.risultatoOperazione===1){
                //alert("controlloVersione2: a="+a+" back="+valoreDiRitorno.versione+" package="+packageJson.version);
                if (versioneCorrenteNonAggiornata(valoreDiRitorno.versione, packageJson.version)) {
                    window.location.reload(true);
                }
            }
        },
        (error) => {window.location.reload(true);}
    ); 
  }

  useEffect(() => {
    //ControlloVersione();
        
    const calcolaDimensioniApp = () => {      
        setDimensioniApp({x:document.documentElement.clientWidth, y:document.documentElement.clientHeight, flagPaginaOrizzontale:document.documentElement.clientWidth>larghezzaMinimaPerPaginaOrizzontale});
    }
    
    calcolaDimensioniApp();
    window.addEventListener('resize', calcolaDimensioniApp);    
    
    return () => {
        window.removeEventListener('resize', calcolaDimensioniApp);
    };
  },[])
  
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Home dimensioniApp={dimensioniApp}/>
      ),
      errorElement: (
        <Home dimensioniApp={dimensioniApp}/>
      ),
    }
  ]);

  if (dimensioniApp === null) {
    return <div>Loading...</div>;
  }

  return <RouterProvider router={router} />;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
    <ContenitoreApp />
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
