

import React, { useEffect, useState } from 'react';
import Clessidra from './Clessidra';

function ContenutoImmagineVideo(props) {
    const [flagCaricato, setFlagCaricato] = useState(false);
    var larghezzaImmagini = 560;
    var proporzioneLarghezzaAltezzaImmagini = 1; // qui metti la ratio (x/y) delle immagini che stai usando in questa app

    var larghezza=larghezzaImmagini;
    if (props.dimensioniApp.flagPaginaOrizzontale) {
        if (larghezza>(props.dimensioniApp.x-80)/2) {
            larghezza = (props.dimensioniApp.x-80)/2;
        }
    } else {
        if (larghezza>props.dimensioniApp.x-120) {
            larghezza = props.dimensioniApp.x-120;
        }
    }
    var altezza = larghezza / proporzioneLarghezzaAltezzaImmagini;
    if (props.urlContenuto) {    
        if (props.tipoContenuto === "mp4") {
            return (
                <div style={{minHeight:altezza, minWidth:larghezza, marginLeft:10, marginRight:10}}>
                    <video 
                        style={{height:altezza, opacity: flagCaricato?1:0, transition: "opacity 1s"}} 
                        onLoadedData={() => setFlagCaricato(true)}
                        autoPlay loop muted webkit-playsinline="true" playsinline="true">
                        <source src={props.urlContenuto} type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                    {!flagCaricato && <Clessidra top={-altezza/1.5} loading={true}/>}
                </div>
            )    
        }
        if (props.tipoContenuto === "jpg" || props.tipoContenuto === "png") {
            return (
                <div style={{minHeight:altezza, minWidth:larghezza, marginLeft:10, marginRight:10}}>
                    <img 
                        style={{height:altezza, objectFit:'contain', opacity: flagCaricato?1:0, transition: "opacity 1s"}}                            
                        onLoad={() => setFlagCaricato(true)}
                        src={props.urlContenuto} 
                        type={"image/"+props.tipoContenuto} 
                        alt='immagine'
                    />
                    {!flagCaricato && <Clessidra top={-altezza/1.5} loading={true}/>}
                </div>
            )    
        }
    }
    if (props.immagine) {
        return (
            <div style={{minHeight:altezza, minWidth:larghezza, marginLeft:10, marginRight:10}}>
                <img src={props.immagine} alt={props.alt} height={altezza}/>
            </div>
        )
    }
    return (<></>)
}

export default ContenutoImmagineVideo;