import React, { useEffect, useState } from 'react';
import ticketFunghi from './immagini/ticketFunghi.jpg';
import imgPalestra from './immagini/palestra.jpg';
import imgGestioneSportiva1 from './immagini/gestionesportiva1.jpg';
import ContenutoImmagineVideo from './ContenutoImmagineVideo';

function Prodotti(props) {
    const altezzaContenitoreHome = 320;

    function DescrizioneGestionaleAssociazioni() {
        return (<div style={{textAlign:'left', padding:"20px"}}>           
            <li>Una applicazione Web e App per smartphone per gestire la tua associazione.</li>
            <li>Semplice, economica, utilizzabile da ovunque, senza installare nulla.</li>
            <li>Gestione degli associati con anagrafica completa, libro soci, direttivo.</li>
            <li>Gestione economica con prima nota, quote, spese, incassi, rimborsi, report per analisi e controllo.</li>
            <li>Eventi, con chat dedicata per ogni evento, presenze e risultati.</li>
            <li>Prenotazione attrezzature o attività.</li>            
            <li>... e molto altro!</li>
            
        </div>)
    }

    function DescrizioneGestionalePalestre() {
        return (<div style={{textAlign:'left', padding:"20px"}}>           
            <li>Una applicazione Web e App per smartphone per gestire la tua palestra o società sportiva.</li>
            <li>Semplice, economica, utilizzabile da ovunque, senza installare nulla.</li>
            <li>Gestione degli abbonati con anagrafica completa, certificati e documenti, quote e scadenze.</li>
            <li>Schede di allenamento personalizzate, su telefonino dell'atleta o stampabili.</li>
            <li>Prenotazione corsi, attrezzature o attività.</li>            
            <li>Gestione allenamenti e gare, con chat dedicata per ogni evento, presenze e risultati.</li>
            <li>Controllo accessi.</li>            
            <li>Gestione economica con prima nota, quote, spese, incassi, rimborsi, report per analisi e controllo.</li>
            <li>... e molto altro!</li>
            
        </div>)
    }

    function DescrizioneTicketOnline() {
        return (<div style={{textAlign:'left', padding:"20px"}}>           
            <li>Una applicazione Web, ottimizzata anche per l'uso da smartphone, per il pagamento di ticket, ad esempio per i permessi di raccolta funghi.</li>
            <li>Semplice, economica, utilizzabile da ovunque, senza installare nulla.</li>
            <li>Impostazione dei ticket in vendita da interfaccia web: costo, validità, caratteristiche.</li>
            <li>Acquisto dei ticket da interfaccia web con pagamento Paypal o Carta di Credito.</li>
            <li>Verifica dei ticket sul campo: APP per verificatori che decodifica il QRCode univoco del ticket inquadrando il telefono dell'utente.</li>            
            <li>Incasso immediatamente disponibile dei ticket venduti.</li>
            <li>Completamente personalizzabile.</li>            
        </div>)
    }

    return (
        <>
        <div style={{
            minHeight:altezzaContenitoreHome,
            backgroundColor: "#F5F5F5",
            marginTop: 30,
            marginBottom: 40,
            marginLeft: 20,
            marginRight: 20,
            paddingTop:5,
            alignItems: "center",
            display:"flex",
            flexDirection: props.dimensioniApp.flagPaginaOrizzontale ? "row" : "column",
            fontSize:16,
            lineHeight:1.4                
        }}
        >
            {props.dimensioniApp.flagPaginaOrizzontale ? <ContenutoImmagineVideo {...props} immagine={imgGestioneSportiva1} alt="Gestione Sportiva"/> : ""}

            <div 
                style={{flex:1, textAlign: "center", marginLeft:10, marginRight:10, marginTop:10, marginBottom:20}}
            >
                <div style={{
                        minHeight:50,
                        paddingLeft:10, 
                        paddingRight:10
                }}>
                    <h2>Gestionale per Associazioni</h2>
                </div>

                {props.dimensioniApp.flagPaginaOrizzontale ? "" : <><div style={{height:20}}/><ContenutoImmagineVideo {...props} immagine={imgGestioneSportiva1} alt="Gestione Sportiva"/><div style={{height:20}}/></>}
    
                <DescrizioneGestionaleAssociazioni/>

                <a href='https://gestionesportiva.it'>Scopri di più →</a>
            </div>
        </div>

        <div style={{
            minHeight:altezzaContenitoreHome,
            backgroundColor: "#F5F5F5",
            marginTop: 30,
            marginBottom: 40,
            marginLeft: 20,
            marginRight: 20,
            paddingTop:5,
            alignItems: "center",
            display:"flex",
            flexDirection: props.dimensioniApp.flagPaginaOrizzontale ? "row" : "column",
            fontSize:16,
            lineHeight:1.4                
        }}            
        >
            {props.dimensioniApp.flagPaginaOrizzontale ? <ContenutoImmagineVideo {...props} immagine={imgPalestra} alt="Gestione Sportiva"/> : ""}

            <div 
                style={{flex:1, textAlign: "center", marginLeft:10, marginRight:10, marginTop:10, marginBottom:20}}
            >
                <div style={{
                        minHeight:50,
                        paddingLeft:10, 
                        paddingRight:10
                }}>
                    <h2>Gestionale per Palestre e Società Sportive</h2>
                </div>

                {props.dimensioniApp.flagPaginaOrizzontale ? "" : <><div style={{height:20}}/><ContenutoImmagineVideo {...props} immagine={imgPalestra} alt="Gestione Sportiva"/><div style={{height:20}}/></>}
    
                <DescrizioneGestionalePalestre/>

                <a href='https://gestionesportiva.it'>Scopri di più →</a>
            </div>
        </div>

        <div style={{
            minHeight:altezzaContenitoreHome,
            backgroundColor: "#F5F5F5",
            marginTop: 30,
            marginBottom: 40,
            marginLeft: 20,
            marginRight: 20,
            paddingTop:5,
            alignItems: "center",
            display:"flex",
            flexDirection: props.dimensioniApp.flagPaginaOrizzontale ? "row" : "column",
            fontSize:16,
            lineHeight:1.4                
        }}
        >
            {props.dimensioniApp.flagPaginaOrizzontale ? <ContenutoImmagineVideo {...props} immagine={ticketFunghi} alt="Ticket Online"/> : ""}

            <div 
                style={{flex:1, textAlign: "center", marginLeft:10, marginRight:10, marginTop:10, marginBottom:20}}
            >
                <div style={{
                        minHeight:50,
                        paddingLeft:10, 
                        paddingRight:10
                }}>
                    <h2>Pagamento ticket online</h2>
                </div>

                {props.dimensioniApp.flagPaginaOrizzontale ? "" : <><div style={{height:20}}/><ContenutoImmagineVideo {...props} immagine={ticketFunghi} alt="Ticket Online"/><div style={{height:20}}/></>}
    
                <DescrizioneTicketOnline/>

                <a href='mailto:info@aigenova.it'>Contattaci per maggiori informazioni →</a>
            </div>
        </div>
    </>
    )
}

export default Prodotti;