import React, { useEffect, useState, useRef } from 'react';
import immagineA from "./immagini/a.png";
import immagineI from "./immagini/i.png";
import immagineG from "./immagini/g.png";
import immagineSopra from "./immagini/sopra.png";
import immagineSotto from "./immagini/sotto.png";
import scritteDestra from "./immagini/scritteDestra.png";
import imgSfondo1 from './immagini/sfondo1-1200.png';
import imgLogo from './immagini/AIG-piccolo.png';

import MenuAlto from './MenuAlto';
import Prodotti from './Prodotti';
import Servizi from './Servizi';
import Chisiamo from './Chisiamo';
import Contatti from './Contatti';

import './App.css';
import 'animate.css'
import { setSelectionRange } from '@testing-library/user-event/dist/utils';

const percorsoUltimaVersione = "https://aigenova.it/versione.json";
const versione = 4 // se non coincide con quella in percorsoUltimaVersione, allora il sito ricarica
const packageJson = require('../package.json');

function Home(props) {

  // parte da -3 per metterci 2 secondi prima di far partire la frase 1, dato che si sta componendo il logo
  const [indiceUltimaFraseVisibile, setIndiceUltimaFraseVisibile] = useState(-3);
  const [immagineSfondoCaricata, setImmagineSfondoCaricata] = useState(false);
  const [numeroFraseDettaglio, setNumeroFraseDettaglio] = useState(0);
  const numeroFrasi = 4;
  const coloreInizialiAIG = "#FFA0A0";

  const sectionProdottiRef = useRef(null);
  const sectionServiziRef = useRef(null);
  const sectionChisiamoRef = useRef(null);
  const sectionContattiRef = useRef(null);

  function scrollToSection(section) {
    switch (section) {
      case 'section-prodotti':
        sectionProdottiRef.current.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'section-servizi':
        sectionServiziRef.current.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'section-chisiamo':
        sectionChisiamoRef.current.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'section-contatti':
        sectionContattiRef.current.scrollIntoView({ behavior: 'smooth' });
        break;
      default:
        break;
    }
  };
    
  useEffect(() => {
    
    const timerId = setTimeout(() => {
      if (indiceUltimaFraseVisibile >= numeroFrasi+1) return; // +1 per il fondo pagina
      setIndiceUltimaFraseVisibile(indiceUltimaFraseVisibile+1);
    }, 500); // millisecondi

    // Pulisci il timeout quando il componente si smonta
    return () => clearTimeout(timerId);
  }, [indiceUltimaFraseVisibile]);

  return (
    <div class="contenitore-intera-pagina">
      
      <div class="contenitore-superiore">
        <div style={{display:'flex'}}>
        
            <div style={{flex:1}}>
              {indiceUltimaFraseVisibile<1 &&
                <>
                <div class="animate__animated animate__fadeIn" style={{paddingTop:20}}>
                  <img style={{height: 102, width: 95}} src={immagineI} alt="AIG"/>
                </div>
                <div class="animate__animated animate__slideInDown animate__slow" style={{marginTop:-105}}>
                  <img style={{height: 102, width: 95}} src={immagineSopra} alt="AIG"/>
                </div>
                <div class="animate__animated animate__slideInUp animate__slow" style={{marginTop:-105}}>
                  <img style={{height: 102, width: 95}} src={immagineSotto} alt="AIG"/>
                </div>
                <div class="animate__animated animate__slideInLeft animate__slow" style={{marginTop:-106}}>
                  <img style={{height: 102, width: 95}} src={immagineA} alt="AIG"/>
                </div>
                <div class="animate__animated animate__slideInRight animate__slow" style={{marginTop:-106}}>
                  <img style={{height: 102, width: 95}} src={immagineG} alt="AIG"/>
                </div>              
                </>
              }
              <img style={{height: 102, width: 95, paddingTop:20, display:indiceUltimaFraseVisibile>=1?"":"none"}} src={imgLogo} alt="AIG"/>
              

              {props.dimensioniApp.x>700 ?
                <div class="animate__animated animate__fadeIn" style={{marginTop:-94, marginLeft: 100, marginBottom:40}}>
                    <img style={{height: 82}} src={scritteDestra} alt="AIG"/>
                </div>
                :
                <div style={{color:"#909090", textAlign: "center", fontSize: 14}}>
                  Azienda Informatica Genova
                </div>
              }
                
            </div>

            <MenuAlto scrollToSection={scrollToSection}/>

        </div>
        
      </div>

        <div class='contenitore-centrale'>
          <img 
            src={imgSfondo1} 
            alt="Azienda Informatica Genova" 
            width={props.dimensioniApp.x} 
            style={immagineSfondoCaricata ? {} : { display: 'none' }} 
            onLoad={() => setImmagineSfondoCaricata(true)}
          />
          {indiceUltimaFraseVisibile>=-1 && immagineSfondoCaricata &&
            <div class="testo-centrato-home animate__animated animate__fadeIn">
              <h1><span style={{color:coloreInizialiAIG}}>A</span>zienda <span style={{color:coloreInizialiAIG}}>I</span>nformatica <span style={{color:coloreInizialiAIG}}>G</span>enova</h1>
              Software di qualità
            </div>
          }
          <section id="section-prodotti" ref={sectionProdottiRef}>
          <h1 style={{textAlign:'center'}}>Prodotti</h1>
          <Prodotti {...props}/> 
          </section>
          <section id="section-servizi" ref={sectionServiziRef}>
          <h1 style={{textAlign:'center'}}>Servizi</h1>
          <Servizi {...props}/>
          </section>
          <section id="section-chisiamo" ref={sectionChisiamoRef}>
          <h1 style={{textAlign:'center'}}>Chi siamo</h1>
          <Chisiamo {...props}/>
          </section>
          <section id="section-contatti" ref={sectionContattiRef}>
          <h1 style={{textAlign:'center'}}>Contatti</h1>
          <Contatti {...props}/>
          </section>

        <div class='contenitore-inferiore'>            
            <div class='voce-menu-inferiore' onClick={() => alert("Questo sito, aigenova.it, non utilizza cookies di tracciamento e non chiede alcun dato personale ai visitatori.")}>Privacy</div>            
            <div style={{fontSize:9, marginTop:20}}>(c) 2024, Azienda Informatica Genova Srl, P.IVA 02927720991</div>
        </div>         
        </div>

    </div>
  );
}

export default Home;
