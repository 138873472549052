import React from "react";
import {useNavigate} from 'react-router-dom';

function MenuAlto(props) {
    const comandoNavigazione = useNavigate();

    function vaiLink(link) {
      comandoNavigazione(link);
      window.scrollTo(0, 0);
    }

    function VoceMenu(props) {
        return (
          <div style={{
            fontSize:15, 
            flex:1,                        
            paddingLeft:20, 
            paddingRight:20,             
            cursor:"pointer", 
            whiteSpace: "nowrap", 
            fontWeight:'bold', 
            fontVariant:'small-caps',
            display: 'flex', // Aggiunto per usare Flexbox
            justifyContent: 'center', // Centrare orizzontalmente
            alignItems: 'center', // Centrare verticalmente
          }}
            onClick={() => 
              {              
                if (props.link) {props.vaiLink(props.link)}
                if (props.section) {props.scrollToSection(props.section)}
              }
            }
          >
            {props.voce}
          </div>
        )
    }
    
    return (
      <div class="contenitore-menu">
      {props.bottoneHome && <VoceMenu {...props} voce="Home" link="/"/>}
      <VoceMenu {...props} voce="Prodotti" section="section-prodotti"/>
      <VoceMenu {...props} voce="Servizi" section="section-servizi"/>
      <VoceMenu {...props} voce="Chi siamo" section="section-chisiamo"/>      
      <VoceMenu {...props} voce="Contatti" section="section-contatti"/>
    </div>)
}

export default MenuAlto;