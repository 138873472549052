import React, { useEffect, useState } from 'react';
import imgConsulenzaInformatica from './immagini/consulenzaInformatica.webp';
import imgSiti from './immagini/siti.png';
import imgFormazione from './immagini/formazione.jpg';
import ContenutoImmagineVideo from './ContenutoImmagineVideo';

function Contatti(props) {
    const altezzaContenitoreHome = 320;

    return (
        <>
        <div style={{
            minHeight:altezzaContenitoreHome,
            backgroundColor: "#F5F5F5",
            marginTop: 30,
            marginBottom: 40,
            marginLeft: 20,
            marginRight: 20,
            alignItems: "center",
            
            fontSize:16,
            lineHeight:1.4,
            padding:20
        }}
        >

<span style={{fontWeight:'bold', fontSize:20}}>Email:</span><br/>
info@aigenova.it<br/>
<br/>
<span style={{fontWeight:'bold', fontSize:20}}>PEC:</span><br/>
aigenova@pec.poste.it<br/>
<br/>
<span style={{fontWeight:'bold', fontSize:20}}>Indirizzo:</span><br/>
via Tommaso Invrea 10/5 scala dx - 16129 - Genova Italy<br/>
                        

        </div>        
    </>
    )
}

export default Contatti;
