import React, { useEffect, useState } from 'react';
import imgConsulenzaInformatica from './immagini/consulenzaInformatica.webp';
import imgSiti from './immagini/siti.png';
import imgFormazione from './immagini/formazione.jpg';
import ContenutoImmagineVideo from './ContenutoImmagineVideo';

function Servizi(props) {
    const altezzaContenitoreHome = 320;

    function DescrizioneConsulenza() {
        return (<div style={{textAlign:'left', padding:"20px"}}>           
            Consulenza per la gestione della tua infrastruttura informatica, dalla progettazione a partire dall'analisi dei requisiti, all'installazione, alla manutenzione ordinaria e straordinaria dei tuoi PC e Server, fisici o in cloud.                        
        </div>)
    }

    function DescrizioneSiti() {
        return (<div style={{textAlign:'left', padding:"20px"}}>           
            Siti Web e applicazioni Cloud costruite su misura per la tua attività, per la tua associazione o per il tuo prodotto.<br/>
            Implementazione di funzionalità specifiche per le tue esigenze, ad esempio gestionale dedicato, catalogo prodotti, vendita online, contenuti dinamici, interfacciamento ai tuoi sistemi informatici...
        </div>)
    }

    function DescrizioneFormazione() {
        return (<div style={{textAlign:'left', padding:"20px"}}>           
            Formazione informatica singola o di gruppo, in presenza o in video lezione, tarata sulle tue esigenze e sui tuoi obiettivi.
        </div>)
    }

    return (
        <>
        
        <div style={{
            minHeight:altezzaContenitoreHome,
            backgroundColor: "#F5F5F5",
            marginTop: 30,
            marginBottom: 40,
            marginLeft: 20,
            marginRight: 20,
            paddingTop:5,
            alignItems: "center",
            display:"flex",
            flexDirection: props.dimensioniApp.flagPaginaOrizzontale ? "row" : "column",
            fontSize:16,
            lineHeight:1.4                
        }}            
        >
            {props.dimensioniApp.flagPaginaOrizzontale ? <ContenutoImmagineVideo {...props} immagine={imgSiti} alt="Gestione Sportiva"/> : ""}

            <div 
                style={{flex:1, textAlign: "center", marginLeft:10, marginRight:10, marginTop:10, marginBottom:20}}
            >
                <div style={{
                        minHeight:50,
                        paddingLeft:10, 
                        paddingRight:10
                }}>
                    <h2>Realizzazione di siti e applicazioni Web personalizzate</h2>
                </div>

                {props.dimensioniApp.flagPaginaOrizzontale ? "" : <><div style={{height:20}}/><ContenutoImmagineVideo {...props} immagine={imgSiti} alt="Gestione Sportiva"/><div style={{height:20}}/></>}
    
                <DescrizioneSiti/>

                <a href='mailto:info@aigenova.it'>Contattaci per maggiori informazioni →</a>
            </div>
        </div>

        <div style={{
            minHeight:altezzaContenitoreHome,
            backgroundColor: "#F5F5F5",
            marginTop: 30,
            marginBottom: 40,
            marginLeft: 20,
            marginRight: 20,
            paddingTop:5,
            alignItems: "center",
            display:"flex",
            flexDirection: props.dimensioniApp.flagPaginaOrizzontale ? "row" : "column",
            fontSize:16,
            lineHeight:1.4                
        }}
        >
            {props.dimensioniApp.flagPaginaOrizzontale ? <ContenutoImmagineVideo {...props} immagine={imgConsulenzaInformatica} alt="Gestione Sportiva"/> : ""}

            <div 
                style={{flex:1, textAlign: "center", marginLeft:10, marginRight:10, marginTop:10, marginBottom:20}}
            >
                <div style={{
                        minHeight:50,
                        paddingLeft:10, 
                        paddingRight:10
                }}>
                    <h2>Consulenza Informatica</h2>
                </div>

                {props.dimensioniApp.flagPaginaOrizzontale ? "" : <><div style={{height:20}}/><ContenutoImmagineVideo {...props} immagine={imgConsulenzaInformatica} alt="Gestione Sportiva"/><div style={{height:20}}/></>}
    
                <DescrizioneConsulenza/>

                <a href='mailto:info@aigenova.it'>Contattaci per maggiori informazioni →</a>
            </div>
        </div>

        <div style={{
            minHeight:altezzaContenitoreHome,
            backgroundColor: "#F5F5F5",
            marginTop: 30,
            marginBottom: 40,
            marginLeft: 20,
            marginRight: 20,
            paddingTop:5,
            alignItems: "center",
            display:"flex",
            flexDirection: props.dimensioniApp.flagPaginaOrizzontale ? "row" : "column",
            fontSize:16,
            lineHeight:1.4                
        }}
        >
            {props.dimensioniApp.flagPaginaOrizzontale ? <ContenutoImmagineVideo {...props} immagine={imgFormazione} alt="Ticket Online"/> : ""}

            <div 
                style={{flex:1, textAlign: "center", marginLeft:10, marginRight:10, marginTop:10, marginBottom:20}}
            >
                <div style={{
                        minHeight:50,
                        paddingLeft:10, 
                        paddingRight:10
                }}>
                    <h2>Corsi di Formazione</h2>
                </div>

                {props.dimensioniApp.flagPaginaOrizzontale ? "" : <><div style={{height:20}}/><ContenutoImmagineVideo {...props} immagine={imgFormazione} alt="Ticket Online"/><div style={{height:20}}/></>}
    
                <DescrizioneFormazione/>

                <a href='mailto:info@aigenova.it'>Contattaci per maggiori informazioni →</a>
            </div>
        </div>
    </>
    )
}

export default Servizi;